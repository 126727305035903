/* eslint-disable import/first */
import '../css/landing/theme.scss';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import jQuery from 'jquery';
import { CountUp } from 'countup.js';

// @ts-ignore
window.jQuery = jQuery;
// @ts-ignore
window.$ = jQuery;
// @ts-ignore
window.CountUp = CountUp;

import '../src/landing_plugins';
import '../src/landing/dropdown';
import '../src/esign_handler';
import '../src/landing/polyfills';
import '../src/landing/navbar';
import '../src/landing/modal';
import '../src/landing/smooth-scroll';
import '../src/landing/choices';
import '../src/landing/countup';
import '../src/landing/popovers';
import '../src/landing/fancybox';
import '../src/landing/allow_numeric';

// @ts-ignore
window.confetti = require('canvas-confetti');

import.meta.glob('../css/img/**/*.*', { eager: true });
