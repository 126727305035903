import { CountUp } from 'countup.js';
import jQuery from 'jquery';

window.CountUp = CountUp;
//
// countup.js
// Theme module
//

jQuery(() => {
  //
  // Variables
  //

  const toggle = document.querySelectorAll('[data-toggle="countup"]');

  //
  // Functions
  //

  function init(Cup, elem) {
    const startVal = elem.dataset.from ? +elem.dataset.from : null;
    const endVal = elem.dataset.to ? +elem.dataset.to : null;

    const decimals = elem.dataset.decimals ? +elem.dataset.decimals : null;
    const duration = elem.dataset.duration ? +elem.dataset.duration : null;
    const options = elem.dataset.options
      ? JSON.parse(elem.dataset.options)
      : null;
    const countUp = new Cup(
      elem,
      startVal,
      endVal,
      decimals,
      duration,
      options,
    );

    if (!countUp.error) {
      countUp.start();
      elem.classList.add('counted');
    } else {
      // eslint-disable-next-line no-console
      console.error(countUp.error);
    }
  }

  //
  // Events
  //
  const countupObj = window.CountUp.CountUp || window.CountUp;
  if (typeof countupObj === 'function' && toggle) {
    [].forEach.call(toggle, (el) => {
      init(countupObj, el);
    });
  }

  document.addEventListener('aos:in:countup:in', (e) => {
    if (e.detail instanceof Element) {
      init(countupObj, e.detail);
    } else {
      const toCount = document.querySelectorAll(
        '.aos-animate[data-aos-id="countup:in"]:not(.counted)',
      );
      toCount.forEach.call(toCount, (el) => {
        init(countupObj, el);
      });
    }
  });
});
