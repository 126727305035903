/* eslint-disable */
import jQuery from 'jquery';

jQuery(document).ready(function(){
  const telInput = jQuery('input[type="tel"]');
  telInput.on('paste', (event) => {
    if (event.originalEvent.clipboardData.getData('Text').match(/[^\d]/)) {
      event.preventDefault();
    }
  });
  telInput.keypress(function (event) {
    const mask = /^\d+$/;
    if (!event.charCode) return true;
    const part1 = this.value.substring(0, this.selectionStart);
    const part2 = this.value.substring(this.selectionEnd, this.value.length);
    if (!mask.test(part1 + String.fromCharCode(event.charCode) + part2)) { return false; }
  });
})
